<template>
  <div class="league" v-if="!page404">
    <XNav
      v-if="leagueInfo"
      :content="[{ t: 'common.leagues' }, { name: leagueInfo.FullName }]"
    />
    <UpcomingEvents v-if="events.length" :content="events" />
    <Bracket
      :numberOfTeams="tournament.NumberOfTeams"
      :fullBracket="tournament.Brackets"
      v-if="tournamentStage"
    />
    <LeagueTable v-if="standings.length > 0" :content="standings" />
    <MostGoals v-if="mostGoal.length > 0" :content="mostGoal" />
    <MostAssists v-if="mostAssists.length > 0" :content="mostAssists" />
  </div>
  <Page404 v-else />
</template>
<script>
import axios from "axios";
import Bracket from "@/components/Bracket.vue";
import MostGoals from "@/components/MostGoals.vue";
import MostAssists from "@/components/MostAssists.vue";
import LeagueTable from "@/components/tables/LeagueTable.vue";
import UpcomingEvents from "@/components/UpcomingEvents.vue";
import XNav from "@/components/XNav.vue";
import Page404 from "@/views/404.vue";
export default {
  name: "League",
  components: {
    MostGoals,
    MostAssists,
    LeagueTable,
    UpcomingEvents,
    XNav,
    Bracket,
    Page404,
  },
  data() {
    return {
      standings: [],
      divisions: [],
      events: [],
      mostGoal: [],
      mostAssists: [],
      leagueInfo: null,
      page404: false,
      tournament: {
        NumberOfTeams: 4,
        Brackets: [[], []],
      },
      tournamentStage: false,
    };
  },
  mounted() {
    let seasonQuery = this.$route.params.seasonID ? "&seasonID=" + this.$route.params.seasonID :"";
    let requests = [
      axios
        .get(this.$apiUrl + "/tournaments?leagueID=" + this.$route.params.id + seasonQuery)
        .then((res) => {
          if (res.data) {
            this.tournament = res.data;
            this.tournamentStage = true;
          }
        }),

      axios
        .get(`${this.$apiUrl}/leagues/${this.$route.params.id}`)
        .then((res) => {
          this.leagueInfo = res.data;
        }),
      axios
        .get(`${this.$apiUrl}/leagueTable/${this.$route.params.id}?${seasonQuery}`)
        .then((res) => {
          this.standings = res.data;
        }),
      axios
        .get(
          `${this.$apiUrl}/leagues/${this.$route.params.id}/events?${seasonQuery}`
        )
        .then((res) => {
          this.events = res.data;
        }),
      axios
        .get(
          `${this.$apiUrl}/leagues/${this.$route.params.id}/most-goals?${seasonQuery}`
        )
        .then((res) => {
          let tmp = res.data;
          tmp.sort((a, b) => (a.goals > b.goals ? -1 : 1));
          for (let p in tmp) {
            if (p != 0 && tmp[p - 1].goals == tmp[p].goals) {
              tmp[p].number = tmp[p - 1].number;
            } else {
              tmp[p].number = Number(p) + 1;
            }
          }
          this.mostGoal = tmp;
        }),

      axios
        .get(
          `${this.$apiUrl}/leagues/${this.$route.params.id}/most-assists?${seasonQuery}`
        )
        .then((res) => {
          let tmp = res.data;
          tmp.sort((a, b) => (a.assists > b.assists ? -1 : 1));
          for (let p in tmp) {
            if (p != 0 && tmp[p - 1].assists == tmp[p].assists) {
              tmp[p].number = tmp[p - 1].number;
            } else {
              tmp[p].number = Number(p) + 1;
            }
          }
          this.mostAssists = tmp;
        }),
    ];
    Promise.all(requests)
      .then(() => this.$store.dispatch("pageLoad", false))
      .catch(() => {
        this.page404 = true;
        this.$store.dispatch("pageLoad", false);
      });
  },
  methods: {
    goTeamPage(item) {
      if (this.$route.params.archiveID) {
        this.$router.push({
          path: `/archive/${this.$route.params.archiveID}/team/${item._id}`,
        });
      } else {
        this.$router.push({ path: `/team/${item._id}` });
      }
    },
  },
};
</script>
