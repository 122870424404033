<template>
  <div class="box">
    <h2>{{ $t("league.mostAssists") }}</h2>
    <div class="most-player" v-if="content">
      <div
        class="first"
        v-if="content[0]"
        @click="$router.push({ path: `/players/${content[0].Player._id}` })"
      >
        <picture>
          <template v-if="content[0].Player.picture">
            <source
              :srcset="`${$bucketUrl}/images/players/${content[0].Player._id}/${content[0].Player.picture}.webp`"
              type="image/webp"
            />
            <source
              :srcset="`${$bucketUrl}/images/players/${content[0].Player._id}/${content[0].Player.picture}.png`"
              type="image/png"
            />
            <img
              class="first-img"
              :src="`${$bucketUrl}/images/players/${content[0].Player._id}/${content[0].Player.picture}.png`"
            />
          </template>
          <template v-else>
            <source srcset="@/assets/default-player.png" type="image/png" />
            <img class="first-img" src="@/assets/default-player.png" />
          </template>
        </picture>
        <div class="first-info">
          <div class="first-info-one">1</div>
          <div
            class="first-info-name"
            :style="{
              'background-image': `url('${$bucketUrl}/images/teams/${content[0].Team._id}.png')`,
            }"
          >
            <div class="first-info-firstname">
              {{ content[0].Player.First_Name }}
            </div>
            <div class="first-info-lastname">
              {{ content[0].Player.Last_Name }}
            </div>
            <div class="first-info-team">{{ content[0].Team.Name }}</div>
          </div>
        </div>
        <div class="first-player-score">
          <span>{{ content[0].assists }}</span>
          <br />{{$t("common.assists")}}
        </div>
      </div>
      <div class="list" v-if="content[1]">
        <XTable
          :x-content="
            content
              .slice(1)
              .sort((a, b) =>
                a.Player.First_Name > b.Player.First_Name ? 1 : -1
              )
          "
          :x-columns="columns"
          sort-by="assists"
          sort-dir="desc"
          @row-click="go"
          class="clickable"
        >
          <template #header[team]>
            <span class="logo-margin">{{ $t("common.team") }}</span>
          </template>
          <template #cell[name]="{ row }"
            >{{ row.Player.First_Name }} {{ row.Player.Last_Name }}</template
          >
          <template #cell[icon]="{ row }">
            <span
              class="team-icon"
              v-bind:style="{
                'background-image': `url('${$bucketUrl}/images/teams/${row.Team._id}.png')`,
              }"
            ></span
          ></template>
          <template #cell[team]="{ row }">
            {{ row.Team.Name }}
          </template>
        </XTable>
      </div>
    </div>
  </div>
</template>

<script>
import XTable from "@/components/tables/XTable.vue";
export default {
  props: ["content"],
  components: {
    XTable,
  },
  data() {
    return {
      columns: [
        { display: "#", name: "number", align: "center" },
        { t: "common.name", name: "name" },
        { display: " ", name: "icon", minimal: true },
        { display: "Team", name: "team", hideable: true },
        { t: "common.assists", name: "assists", align: "center" },
      ],
    };
  },
  methods: {
    go(e) {
      this.$router.push({ path: `/players/${e.Player._id}` });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/theme.scss";
.most-player {
  display: flex;
  flex-direction: column;
}
.first {
  @extend %light-pattern;
  flex-basis: 400px;
  cursor: pointer;
  position: relative;
  .first-img {
    width: calc(100% - 20px);
    display: block;
    margin: 15px 10px 0px 10px;
  }
}
.first-player-score {
  position: absolute;
  top: 10px;
  right: 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  text-align: center;
  font-size: 20px;
  text-transform: uppercase;
  color: $secondary;
  & > span {
    font-size: 60px;
    line-height: 50px;
  }
}
.first-info {
  display: flex;
  align-content: center;
  @extend %secondary-pattern;
}
.first-info-one {
  background: $primary;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 70px;
  line-height: 100px;
  padding: 0 15px;
}
.first-info-name {
  flex-grow: 1;
  color: white;
  font-size: 25px;
  line-height: 30px;
  padding: 5px 100px 5px 5px;
  background-size: auto 150%;
  background-repeat: no-repeat;
  background-position: 110% 25%;
}
.first-info-firstname {
  font-weight: 300;
  font-size: 30px;
}
.first-info-lastname {
  font-weight: 400;
  font-size: 30px;
}
.first-info-team {
  font-weight: 300;
  border-top: 2px solid white;
}

.list {
  flex-grow: 1;
  table {
    height: 100%;
  }
  tr {
    cursor: pointer;
  }
}
@media only screen and (min-width: $mobile-breakpoint) {
  .most-player {
    flex-direction: row;
  }
  .first {
    border-right: 1px solid $secondary;
  }
  .first-info {
    border-bottom: none;
  }
}
</style>