var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('h2',[_vm._v(_vm._s(_vm.$t("league.mostGoals")))]),(_vm.content)?_c('div',{staticClass:"most-player"},[(_vm.content[0])?_c('div',{staticClass:"first",on:{"click":function($event){return _vm.$router.push({ path: `/players/${_vm.content[0].Player._id}` })}}},[_c('picture',[(_vm.content[0].Player.picture)?[_c('source',{attrs:{"srcset":`${_vm.$bucketUrl}/images/players/${_vm.content[0].Player._id}/${_vm.content[0].Player.picture}.webp`,"type":"image/webp"}}),_c('source',{attrs:{"srcset":`${_vm.$bucketUrl}/images/players/${_vm.content[0].Player._id}/${_vm.content[0].Player.picture}.png`,"type":"image/png"}}),_c('img',{staticClass:"first-img",attrs:{"src":`${_vm.$bucketUrl}/images/players/${_vm.content[0].Player._id}/${_vm.content[0].Player.picture}.png`}})]:[_c('source',{attrs:{"srcset":require("@/assets/default-player.png"),"type":"image/png"}}),_c('img',{staticClass:"first-img",attrs:{"src":require("@/assets/default-player.png")}})]],2),_c('div',{staticClass:"first-info"},[_c('div',{staticClass:"first-info-one"},[_vm._v("1")]),_c('div',{staticClass:"first-info-name",style:({
            'background-image': `url('${_vm.$bucketUrl}/images/teams/${_vm.content[0].Team._id}.png')`,
          })},[_c('div',{staticClass:"first-info-firstname"},[_vm._v(" "+_vm._s(_vm.content[0].Player.First_Name)+" ")]),_c('div',{staticClass:"first-info-lastname"},[_vm._v(" "+_vm._s(_vm.content[0].Player.Last_Name)+" ")]),_c('div',{staticClass:"first-info-team"},[_vm._v(_vm._s(_vm.content[0].Team.Name))])])]),_c('div',{staticClass:"first-player-score"},[_c('span',[_vm._v(_vm._s(_vm.content[0].goals))]),_c('br'),_vm._v(_vm._s(_vm.$t("common.goals"))+" ")])]):_vm._e(),(_vm.content[1])?_c('div',{staticClass:"list"},[_c('XTable',{staticClass:"clickable",attrs:{"x-content":_vm.content
            .slice(1)
            .sort((a, b) =>
              a.Player.First_Name > b.Player.First_Name ? 1 : -1
            ),"x-columns":_vm.columns,"sort-by":"goals","sort-dir":"desc"},on:{"row-click":_vm.go},scopedSlots:_vm._u([{key:"header[team]",fn:function(){return [_c('span',{staticClass:"logo-margin"},[_vm._v(_vm._s(_vm.$t("common.team")))])]},proxy:true},{key:"cell[name]",fn:function({ row }){return [_vm._v(_vm._s(row.Player.First_Name)+" "+_vm._s(row.Player.Last_Name))]}},{key:"cell[icon]",fn:function({ row }){return [_c('span',{staticClass:"team-icon",style:({
              'background-image': `url('${_vm.$bucketUrl}/images/teams/${row.Team._id}.png')`,
            })})]}},{key:"cell[team]",fn:function({ row }){return [_vm._v(" "+_vm._s(row.Team.Name)+" ")]}}],null,false,3154288042)})],1):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }