<template>
  <div>
    <div class="box">
      <h2>{{ $t('common.tournament') }}</h2>
      <div
        class="bracket"
        :class="[
          numberOfTeams == 4
            ? 'small'
            : numberOfTeams == 8
            ? 'medium'
            : 'large',
        ]"
      >
        <div class="round" v-for="(_,round) in numberOfRounds" :style="getRoundWidth()" :key="round">
          <p class="roundClass"> {{getRoundLabel(round)}} </p>
          <div
            class="team"
            v-for="(_, team) in numberOfTeamsPerRound[round]" :key="team"
            @click="goToFixture(fullBracket[round][team].Fixture)"
          >
            <div class="team_name" v-bind:class="calcColor(round, team)">
              <p>{{ fullBracket && fullBracket[round][team].Team_Name }}</p>
            </div>
            <div class="event" v-if="fullBracket && fullBracket[round][team].Fixture && !hideFixture">
              <p>{{ fullBracket && fullBracket[round][team].Team_Score }}</p>
              <div class="icon_container" v-if="fullBracket[round][team].Fixture.Datetime && fullBracket[round][team].Fixture.Field">
                <img src="../assets/icons8-calendar-32.png" class="category-icon">
                <div class="text">
                  <p>
                    {{fullBracket[round][team].Fixture.Datetime ? 
                    new Date(fullBracket[round][team].Fixture.Datetime).toLocaleString({timeZone: timeZone},options) : 
                    `${fullBracket[round][team].Fixture.Date} ${fullBracket[round][team].Fixture.Time}`}}
                    | {{ fullBracket[round][team].Fixture.Field }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["numberOfTeams", "fullBracket", "hideFixture"],
  data() {
    return {
      numberOfTeamsPerRound: [],
      numberOfRounds: 0,
      options:{
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      },
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };
  },
  methods: {
    calcColor: function(round, index) {
      let bracket = this.fullBracket[round];
      if(!bracket) return; //bracket hasn't rendered yet, avoid error

      let state = "loss";
      if (index % 2 == 0) {
        if (bracket[index].Team_Score == bracket[index + 1].Team_Score) {
          state = "tie";
        } else if (bracket[index].Team_Score > bracket[index + 1].Team_Score) {
          state = "win";
        }
      } else {
        if (bracket[index].Team_Score == bracket[index - 1].Team_Score) {
          state = "tie";
        } else if (bracket[index].Team_Score > bracket[index - 1].Team_Score) {
          state = "win";
        }
      }
      return state;
    },
    updateNumberOfTeamsPerRound: function() {
      let numberOfTeams = parseInt(this.numberOfTeams);
      this.numberOfTeamsPerRound = [];
      while (numberOfTeams > 1) {
        this.numberOfTeamsPerRound.push(numberOfTeams);
        numberOfTeams = numberOfTeams / 2;
      }
    },
    getRoundLabel: function(round) {
      if(round == this.numberOfRounds-1) return "Final";
      if(round == this.numberOfRounds-2) return "Semi Finals";
      if(round == this.numberOfRounds-3) return "Quarter Finals";
      else return `Round ${round+1}`;
    }, 
    getRoundWidth: function() {
      return `width: ${100/(this.numberOfRounds)}%`;
    },
    goToFixture: function(fixture) {
      if(fixture?._id) this.$router.push({ path: `/event/${fixture._id}`})
    },
  },
  created() {
    this.numberOfRounds = Math.log2(this.numberOfTeams);
    this.updateNumberOfTeamsPerRound();
  },
  watch: {
    numberOfTeams: function() {
      this.numberOfRounds = Math.log2(this.numberOfTeams);
      this.updateNumberOfTeamsPerRound();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/theme.scss";
.bracket {
  display: flex;
  flex-direction: row;
  background-color: white;
  margin-top: 20px;
  width: 100%;
  background-size: cover;
  .round {
    display: flex;
    flex-direction: column;
    max-height: inherit;
    padding-top: 20px;
    padding-bottom: 20px;

    .roundClass {
      font-size: 18px;
      color: #173753;
      text-transform: uppercase;
      text-align: center;
    }
    .team {
      display: flex;
      flex-direction: row;
      cursor: pointer;
      margin: auto 0px auto 0px;
      width: 90%;
      margin-left: 5%;
      height: 30px;
      box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.747);
      background-color: white;

      .team_name{
        display: flex;
        align-items: center;
        width: 80%;
        height: 100%;
        p {
          margin: 0;
          margin-left: 10px;
          font-size: 16px;
          text-align: left;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .event {
        display: flex;
        align-items: center;
        width: 20%;
        height: 100%;
        p {
          margin: 0;
          margin-right: 5px;
          font-size: 16px;
          width: 50%;
          text-align: right;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .icon_container {
          display: flex;
          width: 50%;
        }
        .category-icon {
          width: 80%;
        }
        &:hover {
          .text {
            display: block;
          }
        }
        .text {
          display: none;
          position: absolute;
          background: rgb(0, 0, 0);
          color: white;
          padding: 5px 10px;
          border-radius: 3px;
          margin-top: 25px;
          margin-left: -20%;

          p {
            width: 100%;
          }
        }
      }
    }
    .win {
      border-left: 4px solid rgba(0, 107, 0, 0.664);
    }
    .loss {
      border-left: 4px solid rgba(163, 0, 0, 0.664);
    }
    .tie {
      border-left: 4px solid rgba(46, 46, 46, 0.664);
    }
    .team:nth-child(even) {
      margin-bottom: 5px;
      h1 {
        display: none;
      }
    }
    .team:nth-child(odd) {
      margin-top: 5px;
    }
  }
}
.small {
  height: 400px;
}
.medium {
  height: 490px;
}
.large {
  height: 800px;
}

@media only screen and (max-width: 600px) {

  .bracket {
    .round {
      .roundClass {
        font-size: 12px;
      }
      .team {
        .team_name {
          width: 70%;
          p {
            margin-left: 5px;
            font-size: 9px;
            width: 100%;
          }
        }
        .event {
          width: 30%;

          p {
            margin: 0;
            margin-right: 3px;
            font-size: 9px;
            width: 25%;
          }
          .icon_container {
            width: 75%;
          }
          .category-icon {
            width: 100%;
          }
          .text {
            margin-left: -30%;
            p {
              font-size: 10px;
            }
          }
        }
      }
    }

    .round:last-child {
      .event{
        .text {
          margin-left: -40%;
        }
      }
    }
    
  }
}

</style>
