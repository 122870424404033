var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"box"},[_c('h2',[_vm._v(_vm._s(_vm.$t('common.tournament')))]),_c('div',{staticClass:"bracket",class:[
        _vm.numberOfTeams == 4
          ? 'small'
          : _vm.numberOfTeams == 8
          ? 'medium'
          : 'large',
      ]},_vm._l((_vm.numberOfRounds),function(_,round){return _c('div',{key:round,staticClass:"round",style:(_vm.getRoundWidth())},[_c('p',{staticClass:"roundClass"},[_vm._v(" "+_vm._s(_vm.getRoundLabel(round))+" ")]),_vm._l((_vm.numberOfTeamsPerRound[round]),function(_,team){return _c('div',{key:team,staticClass:"team",on:{"click":function($event){return _vm.goToFixture(_vm.fullBracket[round][team].Fixture)}}},[_c('div',{staticClass:"team_name",class:_vm.calcColor(round, team)},[_c('p',[_vm._v(_vm._s(_vm.fullBracket && _vm.fullBracket[round][team].Team_Name))])]),(_vm.fullBracket && _vm.fullBracket[round][team].Fixture && !_vm.hideFixture)?_c('div',{staticClass:"event"},[_c('p',[_vm._v(_vm._s(_vm.fullBracket && _vm.fullBracket[round][team].Team_Score))]),(_vm.fullBracket[round][team].Fixture.Datetime && _vm.fullBracket[round][team].Fixture.Field)?_c('div',{staticClass:"icon_container"},[_c('img',{staticClass:"category-icon",attrs:{"src":require("../assets/icons8-calendar-32.png")}}),_c('div',{staticClass:"text"},[_c('p',[_vm._v(" "+_vm._s(_vm.fullBracket[round][team].Fixture.Datetime ? new Date(_vm.fullBracket[round][team].Fixture.Datetime).toLocaleString({timeZone: _vm.timeZone},_vm.options) : `${_vm.fullBracket[round][team].Fixture.Date} ${_vm.fullBracket[round][team].Fixture.Time}`)+" | "+_vm._s(_vm.fullBracket[round][team].Fixture.Field)+" ")])])]):_vm._e()]):_vm._e()])})],2)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }