<template>
  <div class="upcoming-events">
    <div ref="eventbox">
      <div
        v-for="(event, index) in content"
        v-bind:key="index"
        class="event"
        :ref="`event`"
        @click="$router.push({ path: `/event/${event._id}` })"
      >
        <div class="teams">
          <div class="team">
            <div
              class="team-logo"
              :style="{
                'background-image': `url('${$bucketUrl}/images/teams/${event.HomeTeam_id}.png')`,
              }"
            ></div>
            <div class="team-name">{{ event.HomeTeam_Name }}</div>
          </div>
          <div class="team">
            <div
              class="team-logo"
              :style="{
                'background-image': `url('${$bucketUrl}/images/teams/${event.AwayTeam_id}.png')`,
              }"
            ></div>
            <div class="team-name">{{ event.AwayTeam_Name }}</div>
          </div>
        </div>
        <div
          class="extra"
          v-if="event.Home_Score != null && event.Away_Score != null"
        >
          {{ event.Home_Score }} - {{ event.Away_Score }}
        </div>
        <div class="extra" v-else>
          {{
            $DateTime
              .fromISO(event.Date)
              .setLocale($i18n.locale)
              .toFormat("MMM d h:mm a")
          }}
        </div>
      </div>
    </div>
    <button class="btn-left" @click="scrollEvents(-1)">&#10096;</button>
    <button class="btn-right" @click="scrollEvents(1)">&#10097;</button>
  </div>
</template>

<script>
export default {
  props: ["content"],
  methods: {
    scrollEvents(value) {
      this.$refs["eventbox"].scrollLeft +=
        (this.$refs["eventbox"].firstElementChild.offsetWidth + 10) * value;
    },
  },
  mounted() {
    let nextEvent = this.content.findIndex(
      (e) => new Date(e.Date).getTime() > Date.now()
    );
    setTimeout(() => {
      if (nextEvent != -1) {
        let offset = this.$refs["event"][nextEvent].offsetLeft;
        this.$refs["eventbox"].scrollLeft = offset;
      } else {
        this.$refs[`eventbox`].lastElementChild.scrollIntoView();
      }
    }, 200);
  },
};
</script>

<style lang="scss" scoped>
@import "@/main.scss";

.upcoming-events {
  position: relative;
  height: 150px;
  overflow-y: hidden;
  margin: 15px 0;

  & > div {
    margin: 0 30px;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    height: 100%;
    display: flex;
    scrollbar-width: none;
    scroll-snap-type: x proximity;

    &::-webkit-scrollbar {
      height: 0 !important;
    }
  }

  button {
    position: absolute;
    top: 0;
    height: 150px;
    width: 30px;
    border: none;
    background: $primary;
    color: white;

    &:hover {
      background: darken($primary, 2);
    }

    &.btn-right {
      right: 0;
    }

    &.btn-left {
      left: 0;
    }
  }
}

.event {
  cursor: pointer;
  @extend %light-pattern;
  border: 2px solid $secondary;
  min-width: 250px;
  margin: 0 5px;
  display: flex;
  flex-direction: column;
  scroll-snap-align: start;
  scroll-margin: 0 5px;

  &:hover {
    filter: brightness(0.9);
  }
}

.teams {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
}

.team {
  width: 50%;
  display: flex;
  flex-direction: column;

  &:first-child {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      right: 0px;
      top: 20%;
      height: 60%;
      border-right: 1px solid $secondary;
    }
  }
}

.team-logo {
  flex-grow: 1;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin: 10px 10px 0 10px;
}

.team-name {
  font-size: 12px;
  text-align: center;
  color: $secondary;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 10px 10px 10px;
}

.extra {
  background: $secondary;
  color: white;
  text-align: center;
  font-size: 15px;
}

@media only screen and (max-width: $mobile-breakpoint) {
  .upcoming-events {
    height: 100px;

    & > div {
      margin: 0;
    }

    button {
      display: none;
    }
  }

  .event {
    min-width: 150px;
  }

  .team-logo {
    margin: 10px;
  }

  .team-name {
    display: none;
  }
}
</style>
